import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 18 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M15.75 4.906a76 76 0 0 0-7.515-.375q-2.228 0-4.455.225l-1.53.15m4.125-.756.165-.983c.12-.713.21-1.245 1.478-1.245h1.964c1.268 0 1.366.562 1.478 1.252l.165.975m2.512 3.128-.487 7.553c-.083 1.177-.15 2.092-2.243 2.092H6.592c-2.092 0-2.16-.915-2.242-2.092l-.488-7.553m3.886 5.52h2.497m-3.12-3h3.75"
    }, null, -1)
  ])))
}
export default { render: render }